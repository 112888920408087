<template>
  <div class="container_box">
    <div class="table1">
      <div class="title">消防管理部门检查记录</div>
      <div class="table">
        <el-table :data="tableData1" border stripe height="100%">
          <el-table-column type="index" width="50" label="序号" align="center"></el-table-column>
          <el-table-column align="center" v-for="(item, index) in columns1" :key="index" :prop="item.prop" :label="item.label"></el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination @current-change="currentChange1" :total="total1" :current-page.sync="pageNo1" :page-size="pageSize" layout="total, prev, pager, next" background>
        </el-pagination>
      </div>
    </div>
    <div class="table2">
      <div class="title">消防单位自我检查记录</div>
      <div class="table">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="NFC巡检" name="first"></el-tab-pane>
          <el-tab-pane label="防火巡查" name="second"></el-tab-pane>
          <el-tab-pane label="值班记录" name="third"></el-tab-pane>
          <el-tab-pane label="保养备案" name="fourth"></el-tab-pane>
        </el-tabs>
        <el-table :data="tableData2" border stripe height="100%">
          <template v-if="activeName === 'first'">
            <el-table-column prop="checkResult" label="检查结果" show-overflow-tooltip width="200" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="default" v-show="scope.row.checkResult===1">自我检查合格</el-button>
                <el-button type="danger" size="default" v-show="scope.row.checkResult===0">自我检查不合格</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="checkTime" label="核查时间" show-overflow-tooltip width="120" align="center">
              <template slot-scope="scope">
                {{scope.row.checkTime|timeFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="patrolPerson" label="巡查人" show-overflow-tooltip width="100" align="center">
            </el-table-column>
            <el-table-column prop="checkPerson" label="核查人" show-overflow-tooltip width="100" align="center">
            </el-table-column>
            <el-table-column prop="safeHead" label="安全主管" show-overflow-tooltip width="100" align="center">
            </el-table-column>
            <el-table-column prop="isFire" label="用火、用电有无违章情况" show-overflow-tooltip width="180" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isFire===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isFire===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isExit" label="安全出口、安全疏散通道是否畅通" show-overflow-tooltip width="240" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isExit===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isExit===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isLight" label="安全疏散疏散指示标志、应急照明是否完好" show-overflow-tooltip width="300" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isLight===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isLight===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isFlag" label="消防设施、器材和消防安全标志是否到位、完整" show-overflow-tooltip width="320" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isFlag===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isFlag===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isDoor" label="常闭式防火门是否处于关闭状态" show-overflow-tooltip width="220" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isDoor===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isDoor===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isThing" label="防火卷帘下是否堆放物品影响使用" show-overflow-tooltip width="240" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isThing===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isThing===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isPerson" label="消防安全重点部位的人员在岗情况" show-overflow-tooltip width="240" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isPerson===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isPerson===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isOthers" label="其他消防安全情况" show-overflow-tooltip width="150" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isOthers===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isOthers===0">是</el-tag>
              </template>
            </el-table-column>
          </template>
          <template v-if="activeName === 'second'">
            <el-table-column prop="checkResult" label="检查结果" show-overflow-tooltip width="200" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="default" v-show="scope.row.checkResult===1">自我检查合格</el-button>
                <el-button type="danger" size="default" v-show="scope.row.checkResult===0">自我检查不合格</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="checkTime" label="核查时间" show-overflow-tooltip width="120" align="center">
              <template slot-scope="scope">
                {{scope.row.checkTime|timeFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="rectifyDays" label="整改时间" show-overflow-tooltip width="120" align="center">
              <template slot-scope="scope">
                {{scope.row.rectifyDays|timeFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="patrolPerson" label="巡查人" show-overflow-tooltip width="100" align="center">
            </el-table-column>
            <el-table-column prop="checkPerson" label="核查人" show-overflow-tooltip width="100" align="center">
            </el-table-column>
            <el-table-column prop="safeHead" label="安全主管" show-overflow-tooltip width="100" align="center">
            </el-table-column>
            <el-table-column prop="isFire" label="用火、用电有无违章情况" show-overflow-tooltip width="180" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isFire===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isFire===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isExit" label="安全出口、安全疏散通道是否畅通" show-overflow-tooltip width="240" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isExit===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isExit===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isLight" label="安全疏散疏散指示标志、应急照明是否完好" show-overflow-tooltip width="300" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isLight===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isLight===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isFlag" label="消防设施、器材和消防安全标志是否到位、完整" show-overflow-tooltip width="320" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isFlag===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isFlag===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isDoor" label="常闭式防火门是否处于关闭状态" show-overflow-tooltip width="220" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isDoor===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isDoor===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isThing" label="防火卷帘下是否堆放物品影响使用" show-overflow-tooltip width="240" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isThing===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isThing===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isPerson" label="消防安全重点部位的人员在岗情况" show-overflow-tooltip width="240" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isPerson===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isPerson===0">是</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="isOthers" label="其他消防安全情况" show-overflow-tooltip width="150" align="center">
              <template slot-scope="scope">
                <el-tag type="success" size="normal" v-show="scope.row.isOthers===1">否</el-tag>
                <el-tag type="danger" size="normal" v-show="scope.row.isOthers===0">是</el-tag>
              </template>
            </el-table-column>
          </template>
          <template v-if="activeName === 'third'">
            <el-table-column prop="roomName" label="消防室控制室名称" show-overflow-tooltip width="150" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="default">{{scope.row.roomName}}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="personName" label="值班管理人员" show-overflow-tooltip width="100" align="center">
            </el-table-column>
            <el-table-column prop="dutyTime" label="值班日期" show-overflow-tooltip width="100" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.dutyTime|timeFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="dutyType" label="值班班次" show-overflow-tooltip width="100" align="center">
              <template slot-scope="scope">
                <el-tag type="primary" size="normal" v-show="scope.row.dutyType===1">早班</el-tag>
                <el-tag type="primary" size="normal" v-show="scope.row.dutyType===2">中班</el-tag>
                <el-tag type="primary" size="normal" v-show="scope.row.dutyType===3">晚班</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="消防控制室值班情况" show-overflow-tooltip width="100" align="center">
              <el-table-column label="火灾报警控制器运行情况" show-overflow-tooltip width="100" align="center">
                <el-table-column prop="deviceRun" label="设备运行情况" show-overflow-tooltip width="100" align="center">
                  <template slot-scope="scope">
                    <el-tag type="success" size="normal" v-show="scope.row.deviceRun===1">异常</el-tag>
                    <el-tag type="danger" size="normal" v-show="scope.row.deviceRun===0">正常</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="checkTrouble" label="故障及处理情况" show-overflow-tooltip width="100" align="center">
                </el-table-column>
              </el-table-column>
              <el-table-column label="控制室内其他消防系统运行情况" show-overflow-tooltip width="100" align="center">
                <el-table-column prop="deviceName" label="设备名称" show-overflow-tooltip width="100" align="center">
                </el-table-column>
                <el-table-column prop="otherControlStatus" label="控制状态" show-overflow-tooltip width="100" align="center">
                  <template slot-scope="scope">
                    <el-tag type="primary" size="normal" v-show="scope.row.deviceRun===1">电动</el-tag>
                    <el-tag type="primary" size="normal" v-show="scope.row.deviceRun===0">手动</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="otherDeviceRun" label="设备运行情况" show-overflow-tooltip width="100" align="center">
                  <template slot-scope="scope">
                    <el-tag type="success" size="normal" v-show="scope.row.deviceRun===1">异常</el-tag>
                    <el-tag type="danger" size="normal" v-show="scope.row.deviceRun===0">正常</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="otherTrouble" label="故障及处理情况" show-overflow-tooltip width="100" align="center">
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <el-table-column label="火灾报警器日常检查情况" show-overflow-tooltip width="100" align="center">
              <el-table-column prop="checkContent" label="检查内容" show-overflow-tooltip width="100" align="center">
              </el-table-column>
              <el-table-column prop="checkTime" label="检查时间" show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.checkTime|timeFormat}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="checkPerson" label="检查人" show-overflow-tooltip width="100" align="center">
              </el-table-column>
              <el-table-column prop="trouble" label="故障及处理情况" show-overflow-tooltip width="100" align="center">
              </el-table-column>
            </el-table-column>
          </template>
          <template v-if="activeName === 'fourth'">
            <el-table-column prop="conclusion" label="维护保养结论" show-overflow-tooltip width="140">
            </el-table-column>
            <el-table-column prop="type" label="维护类型" show-overflow-tooltip width="120">
              <template slot-scope="scope">
                <span v-show="scope.row.type===310">个人维护</span>
                <span v-show="scope.row.type===311">外部单位维护</span>
              </template>
            </el-table-column>
            <el-table-column prop="month" label="月份" show-overflow-tooltip>
              <template slot-scope="scope">
                {{scope.row.month|monthFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="fireAutoAlarm" label="火灾自动报警系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.fireAutoAlarm===306">正常运行</span>
                <span v-show="scope.row.fireAutoAlarm===307">故障运行</span>
                <span v-show="scope.row.fireAutoAlarm===308">瘫痪停用</span>
                <span v-show="scope.row.fireAutoAlarm===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="autoWaterFire" label="自动喷水灭火系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.autoWaterFire===306">正常运行</span>
                <span v-show="scope.row.autoWaterFire===307">故障运行</span>
                <span v-show="scope.row.autoWaterFire===308">瘫痪停用</span>
                <span v-show="scope.row.autoWaterFire===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="frothFire" label="泡沫灭火系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.frothFire===306">正常运行</span>
                <span v-show="scope.row.frothFire===307">故障运行</span>
                <span v-show="scope.row.frothFire===308">瘫痪停用</span>
                <span v-show="scope.row.frothFire===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="gasFire" label="气体灭火系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.gasFire===306">正常运行</span>
                <span v-show="scope.row.gasFire===307">故障运行</span>
                <span v-show="scope.row.gasFire===308">瘫痪停用</span>
                <span v-show="scope.row.gasFire===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="dryPowerFire" label="干粉灭火系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.dryPowerFire===306">正常运行</span>
                <span v-show="scope.row.dryPowerFire===307">故障运行</span>
                <span v-show="scope.row.dryPowerFire===308">瘫痪停用</span>
                <span v-show="scope.row.dryPowerFire===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="waterMistFire" label="细水雾灭火系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.waterMistFire===306">正常运行</span>
                <span v-show="scope.row.waterMistFire===307">故障运行</span>
                <span v-show="scope.row.waterMistFire===308">瘫痪停用</span>
                <span v-show="scope.row.waterMistFire===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="dischargeSmoke" label="排烟系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.dischargeSmoke===306">正常运行</span>
                <span v-show="scope.row.dischargeSmoke===307">故障运行</span>
                <span v-show="scope.row.dischargeSmoke===308">瘫痪停用</span>
                <span v-show="scope.row.dischargeSmoke===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="preventSmoke" label="防烟系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.preventSmoke===306">正常运行</span>
                <span v-show="scope.row.preventSmoke===307">故障运行</span>
                <span v-show="scope.row.preventSmoke===308">瘫痪停用</span>
                <span v-show="scope.row.preventSmoke===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="roomInsideWater" label="室内消防给水系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.roomInsideWater===306">正常运行</span>
                <span v-show="scope.row.roomInsideWater===307">故障运行</span>
                <span v-show="scope.row.roomInsideWater===308">瘫痪停用</span>
                <span v-show="scope.row.roomInsideWater===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="roomOutWater" label="室外消防给水系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.roomOutWater===306">正常运行</span>
                <span v-show="scope.row.roomOutWater===307">故障运行</span>
                <span v-show="scope.row.roomOutWater===308">瘫痪停用</span>
                <span v-show="scope.row.roomOutWater===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="light" label="疏散指示标志和应急照明" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.light===306">正常运行</span>
                <span v-show="scope.row.light===307">故障运行</span>
                <span v-show="scope.row.light===308">瘫痪停用</span>
                <span v-show="scope.row.light===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="electricity" label="消防供电配电" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.electricity===306">正常运行</span>
                <span v-show="scope.row.electricity===307">故障运行</span>
                <span v-show="scope.row.electricity===308">瘫痪停用</span>
                <span v-show="scope.row.electricity===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="fireBroadcast" label="火灾应急广播系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.fireBroadcast===306">正常运行</span>
                <span v-show="scope.row.fireBroadcast===307">故障运行</span>
                <span v-show="scope.row.fireBroadcast===308">瘫痪停用</span>
                <span v-show="scope.row.fireBroadcast===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="elevator" label="消防电梯" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.elevator===306">正常运行</span>
                <span v-show="scope.row.elevator===307">故障运行</span>
                <span v-show="scope.row.elevator===308">瘫痪停用</span>
                <span v-show="scope.row.elevator===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="other" label="其他系统" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.other===306">正常运行</span>
                <span v-show="scope.row.other===307">故障运行</span>
                <span v-show="scope.row.other===308">瘫痪停用</span>
                <span v-show="scope.row.other===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="preventFire" label="防火分隔" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.preventFire===306">正常运行</span>
                <span v-show="scope.row.preventFire===307">故障运行</span>
                <span v-show="scope.row.preventFire===308">瘫痪停用</span>
                <span v-show="scope.row.preventFire===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="cutFire" label="灭火器" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.cutFire===306">正常运行</span>
                <span v-show="scope.row.cutFire===307">故障运行</span>
                <span v-show="scope.row.cutFire===308">瘫痪停用</span>
                <span v-show="scope.row.cutFire===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="deviceFunction" label="控制室联动控制设备功能" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.deviceFunction===306">正常运行</span>
                <span v-show="scope.row.deviceFunction===307">故障运行</span>
                <span v-show="scope.row.deviceFunction===308">瘫痪停用</span>
                <span v-show="scope.row.deviceFunction===309">拆除</span>
              </template>
            </el-table-column>
            <el-table-column prop="exits" label="消防通道" show-overflow-tooltip width="140">
              <template slot-scope="scope">
                <span v-show="scope.row.exits===306">正常运行</span>
                <span v-show="scope.row.exits===307">故障运行</span>
                <span v-show="scope.row.exits===308">瘫痪停用</span>
                <span v-show="scope.row.exits===309">拆除</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="page">
        <el-pagination @current-change="currentChange2" :total="total2" :current-page.sync="pageNo2" :page-size="pageSize" layout="total, prev, pager, next" background>
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      total1: 0,
      total2: 0,
      pageSize: 14,
      pageNo1: 1,
      pageNo2: 1,
      columns1: [
        {
          prop: 'problem',
          label: '隐患问题'
        },
        {
          prop: 'situation',
          label: '整改情况'
        },
        {
          prop: 'time',
          label: '整改时限'
        },
        {
          prop: 'memo',
          label: '备注'
        }
      ],
      tableList1: [],
      tableData1: [],
      num1: 1,
      timer: null,
      timer1: null,
      timeOut: null,
      activeName: 'first',
      tableData2: []
    }
  },
  created () {
    for (let i = 0; i < 70; i++) {
      const obj = {
        problem: '高压电工持证人员不足' + i,
        situation: '已另外聘请1名持证电工',
        time: '3月31日',
        memo: '已完成'
      }
      this.tableList1.push(obj)
    }
    this.total1 = this.tableList1.length
    this.dataCut(this.tableList1, this.tableData1, this.pageSize, this.pageNo1)
    this.currentPage()
    this.getNfcList()
    this.cureentPage1()
  },
  destroyed () {
    clearInterval(this.timer)
    clearInterval(this.timer1)
    clearTimeout(this.timeOut)
  },
  methods: {
    // 截取数据 模拟分页
    dataCut (arr, arr1, pagesize, pageno) {
      if (arr.length < pagesize) {
        arr1 = arr
      } else {
        if (pageno === 1) {
          for (let i = 0; i < arr.length; i++) {
            if (i < pagesize) {
              arr1.push(arr[i])
            } else {
              break
            }
          }
        } else {
          for (let j = 0; j < arr.length; j++) {
            if (j >= (pageno - 1) * pagesize && j < pageno * pagesize) {
              arr1.push(arr[j])
            }
          }
        }
      }
    },
    // 表一分页
    currentChange1 (val) {
      this.pageNo1 = val
      this.num1 = val
      this.tableData1 = []
      this.dataCut(this.tableList1, this.tableData1, this.pageSize, this.pageNo1)
    },
    // 表一翻页
    currentPage () {
      const pageCount = this.total1 % this.pageSize === 0 ? parseInt(this.total1 / this.pageSize) : parseInt(this.total1 / this.pageSize) + 1
      if (pageCount !== 1) {
        this.timer = setInterval(() => {
          if (this.num1 <= pageCount) {
            this.currentChange1(this.num1)
            this.num1++
          } else if (this.num1 > pageCount) {
            clearInterval(this.timer)
            this.num1 = 1
            this.currentPage()
          }
        }, 3000)
      }
    },
    // 表二标签页点击事件
    handleClick (val) {
      clearInterval(this.timer1)
      clearTimeout(this.timeOut)
      this.activeName = val.name
      this.getList()
      this.timeOut = setTimeout(() => { this.cureentPage1() }, 5000)
    },
    // 表二分页
    currentChange2 (val) {
      clearInterval(this.timer1)
      clearTimeout(this.timeOut)
      this.pageNo2 = val
      this.getList()
      this.timeOut = setTimeout(() => { this.cureentPage1() }, 5000)
    },
    // 分类获取表二的数据
    getList () {
      this.tableData2 = []
      if (this.activeName === 'first') {
        this.getNfcList()
      } else if (this.activeName === 'second') {
        this.getFireList()
      } else if (this.activeName === 'third') {
        this.getDutyList()
      } else if (this.activeName === 'fourth') {
        this.getBaoYangList()
      }
    },
    // 表二翻页
    cureentPage1 () {
      const arr = ['first', 'second', 'third', 'fourth']
      const pageCount = this.total2 % this.pageSize === 0 ? parseInt(this.total2 / this.pageSize) : parseInt(this.total2 / this.pageSize) + 1
      if (pageCount > 1) {
        this.timer1 = setInterval(() => {
          console.log('触发了定时器timer1')
          if (this.pageNo2 <= pageCount) {
            this.pageNo2++
            this.getList()
          } else if (this.pageNo2 > pageCount) {
            clearInterval(this.timer1)
            this.pageNo2 = 1
            let num2 = 0
            arr.forEach((item, index) => {
              if (item === this.activeName) {
                num2 = index
              }
            })
            if (num2 + 1 <= arr.length - 1) {
              this.activeName = arr[num2 + 1]
            } else {
              this.activeName = arr[0]
            }
            this.handleClick({ name: this.activeName })
          }
        }, 5000)
      } else {
        this.pageNo2 = 1
        let num2 = 0
        arr.forEach((item, index) => {
          if (item === this.activeName) {
            num2 = index
          }
        })
        if (num2 + 1 <= arr.length - 1) {
          this.activeName = arr[num2 + 1]
        } else {
          this.activeName = arr[0]
        }
        this.handleClick({ name: this.activeName })
      }
    },
    // 获取NFC巡检
    async getNfcList () {
      const { data: res } = await this.$axios.get('/nfcPatrol/list', {
        params: {
          pageNo: this.pageNo2,
          pageSize: this.pageSize
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.tableData2 = res.data.result
      this.total2 = res.data.total
    },
    // 获取防火巡检
    async getFireList () {
      const { data: res } = await this.$axios.get('/firePatrol/list', {
        params: {
          pageNo: this.pageNo2,
          pageSize: this.pageSize
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.tableData2 = res.data.result
      this.total2 = res.data.total
    },
    // 获取值班记录
    async getDutyList () {
      const { data: res } = await this.$axios.get('/fireControlDutyRecord/list', {
        params: {
          pageNo: this.pageNo2,
          pageSize: this.pageSize
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.tableData2 = res.data.result
      this.total2 = res.data.total
    },
    // 获取保养记录
    async getBaoYangList () {
      const { data: res } = await this.$axios.get('/deviceProtectReport/list', {
        params: {
          pageNo: this.pageNo2,
          pageSize: this.pageSize
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.tableData2 = res.data.result
      this.total2 = res.data.total
    }
  }
}
</script>

<style lang="scss">
.container_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  .table1,
  .table2 {
    width: 100%;
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(30, 74, 115, 0.5);
    .title {
      color: #fff;
      padding-left: 15px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-left: 5px solid #f5f5f5;
    }
    .table {
      flex: 12;
      width: 100%;
      overflow: hidden;
      .el-table,
      .el-table__expanded-cell {
        background-color: transparent;
        tr,
        th {
          background-color: transparent;
          color: #fff;
          .el-table__expand-icon {
            color: #fff;
          }
        }
        .el-table--enable-row-hover,
        .hover-row > td,
        tr:hover > td {
          background-color: rgba(7, 104, 159, 0.15) !important;
        }
        .el-table__row--striped > td {
          background-color: rgba(7, 104, 159, 0.15) !important;
        }
      }
      ::v-deep  .el-tabs__item {
        color: #fff;
      }
      .el-tabs__header {
        margin: 0 !important;
        border-bottom: none !important;
      }
      .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
        background-color: rgba(7, 104, 159, 0.5) !important;
        border-bottom: none !important;
      }
    }
    .page {
      margin-top: 10px;
      ::v-deep  .el-pagination__total {
        color: #fff;
      }
    }
  }
  .table2 {
    margin-right: 0 !important;
    margin-left: 10px;
    .table {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      .el-table {
        flex: 10;
      }
    }
  }
}
</style>
